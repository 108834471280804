import './bootstrap';
import '../css/app.css';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';

import React from 'react';
import {createRoot} from 'react-dom/client';
import {createInertiaApp} from '@inertiajs/react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from "@sentry/react";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

Sentry.init({dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC});

createInertiaApp({
    progress: {
        color: '#323A51'
    },
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({el, App, props}) {
        const root = createRoot(el);

        root.render(<App {...props} />);
    },
});
